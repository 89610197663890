import { Observable, forkJoin } from "rxjs";
import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Config } from "../domain/instance-menagement/dto/ui-config";
import {PartnerData} from "../domain/instance-menagement/dto/partner-data";
import {environment} from "../../environments/environment";

export class InstanceApi {
  private httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  constructor() { };
  
  public create(cfg: Config): Observable<any> {
    const observableArr: Observable<any>[] = this.createArrayOfObservalbes(cfg);
    
    return forkJoin(observableArr).pipe(
      map(res => res)
    )
  }

  public update(instanceId: string, cfg: Config): Observable<any> {
    const observableArr: Observable<any>[] = this.createArrayOfObservalbes(cfg);
    
    return forkJoin(observableArr).pipe(
      map(res => res)
    )
  }

  public delete(instanceId: string): Observable<any> {
    return this.httpClient.delete(`https://jsonplaceholder.typicode.com/todos/${instanceId}`).pipe(
      map(res => res)
    );
  }

  public getConfig(instanceName: string, language: string): Observable<Config> {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/json");
    return this.httpClient.get<Config>(`${environment.api_host}enrollment_widget/config/${instanceName}?language=${language}`, {headers: headers}).pipe(
      map(res => res)
    );
  }

  private createArrayOfObservalbes(cfg: Config): Observable<any>[] {
    const requestArr: Observable<any>[] = [];
    return requestArr;
  }

  public getPartner(token: string): Observable<PartnerData> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/ld+json");
    return this.httpClient.get<PartnerData>(environment.api_host + `non_auth_initialize/${token}`, {
      headers: headers
    }).pipe(
      map(res => res)
    );
  }

  public getTranslationFromUrl(url: string): Observable<object> {
    return this.httpClient.get(url)
      .pipe(map(data => data));
  }
}
