import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-instance-selector',
  templateUrl: './instance-selector.component.html',
  styleUrls: ['./instance-selector.component.scss']
})
export class InstanceSelectorComponent implements OnInit {
  private instances: {id: string, name: string}[] = [];
  public filteredInstances: {id: string, name: string}[] = [];

  @Output() instanceOutput = new EventEmitter<Object>()

  constructor(private config: ConfigService) { }

  ngOnInit(): void {
    this.config.getInstancesList().subscribe(instances => {
      this.instances = instances;
      this.filteredInstances = instances;
    })
  }

  filterInstances(value: string) {
    if(!value) {
      this.filteredInstances = this.instances;
    } else {
      const text = value.toLowerCase();
      this.filteredInstances = this.instances.filter(instance => {
        return (instance.id.toLowerCase().indexOf(text) > -1 || instance.name.toLowerCase().indexOf(text) > -1);
      })
    }
  }

  emitInstance(instance: {id: string, name: string}) {
    this.instanceOutput.emit(instance)
  }
}
