import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { PartnerData } from "../../domain/instance-menagement/dto/partner-data";
import { EMPTY, Observable, of } from "rxjs";
import { InstanceMenagement } from "../../domain/instance-menagement/instance-menagement";
import { InstanceApi } from "../../adapters/instance-api";
import { ConfigService } from '../services/config.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ConfigResolver implements Resolve<PartnerData> {
  private instanceMgmt = new InstanceMenagement(new InstanceApi());

  constructor(
    private router: Router,
    private config: ConfigService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const partnerData = JSON.parse(sessionStorage.getItem('partnerData'));
    if (!partnerData || this.config.checkConfig()) {
      return of(EMPTY);
    } else {
      return this.instanceMgmt.getConfigOfInstance(partnerData['companyId'], partnerData['language'] || 'pl').pipe(
        tap(instanceConfig => {
          this.config.applyConfig(instanceConfig);
        })
      )
    }
  }
}
