import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderVisible: Subject<boolean> = new Subject<boolean>();
  
  public showLoader() {
    this.loaderVisible.next(true);
  }

  public hideLoader() {
    this.loaderVisible.next(false);
  }
}
