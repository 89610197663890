import { AES } from './AES';
import { RSA } from './RSA';

export class KeyWrapper {
  constructor() {}

  wrap(data: string, key: string): any {
    const aes: AES = new AES(),
      rsa: RSA = new RSA(),
      encryptedPan : string = aes.encrypt(data),
      encryptedKey: string = rsa.sign(aes.getKey(), key);

      return {encryptedPan, encryptedKey};
  }
}
