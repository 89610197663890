import {Component, ElementRef, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {ModalService} from "../../shared/services/modal.service";
import {ModalInterface} from "../../domain/enrollment/dto/modal-data";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {
  modalEventsSubscription: Subscription;
  modalData: ModalInterface;

  constructor(public modal: ModalService) {
    this.modalEventsSubscription = this.modal.modalOpenEvent$.subscribe(
      res => this.openModal(res)
    )
  }

  openModal(data){
    this.modalData  = data;
  }
  closeModal(status: string){
    this.modalData = {};
    this.modal.close(status)
  }

}
