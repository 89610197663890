export class LuhnValidator {
    static check(cardNumber: string):boolean {
        const numbersArray: Array<number> = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
          testNumber: string = cardNumber.toString().replace(/[ -]/g, '');
        let len: number = testNumber.length,
          bit: number = 1,
          sum: number = 0,
          value: number;
      
        while (len) {
          value = parseInt(testNumber.charAt(--len), 10);
          sum += (bit ^= 1) ? numbersArray[value] : value;
        }
      
        return sum && sum % 10 === 0;
    }
}
