import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

@Injectable()
export class RedirectService {
  private renderer: Renderer2;
  private counter: number = 0;

  constructor(rendererFactory: RendererFactory2, private router: Router, private config: ConfigService) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.config.getAppConfig().subscribe(res => {
      this.listenOnKeydown(res.key_combination, res.times_to_repeat);
    })
  }

  listenOnKeydown(keyCombination: string[], timesToRepeat: number) {
      this.renderer.listen(document, `keydown.${keyCombination[0]}.${keyCombination[1]}`, () => {
        this.counter++;
        setTimeout(() => this.counter = 0, 1000);
        if(this.counter === timesToRepeat) {
          sessionStorage.setItem('allow-configuration', 'true');
          this.router.navigate(['/instance-configuration']);
        }
    })
  }
}
