import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { CardNumberValidator } from '../../shared/validators/card-number.validator';
import { ConfigService } from '../../shared/services/config.service';
import { Enroll } from '../../domain/enrollment/enroll'
import { EnrollmentApi } from '../../adapters/enrollment-api'
import { KeyWrapper } from "../../domain/utils/crypto/KeyWrapper";
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from '../../shared/services/loader.service';
import { MobileEventService } from 'src/app/shared/services/mobile-event.service';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy, AfterViewInit {
  public form: FormGroup;
  public termsUrl: string = '';
  private enroll = new Enroll(new EnrollmentApi(), new KeyWrapper());
  private termsSub: Subscription;
  private availableFieldsSub: Subscription;
  private requiredFieldsSub: Subscription;
  private timeout: Subscription;
  private showConfirmationScreen: boolean = false;
  public showAdditionalGraphics: boolean = true;
  public optionalFields: Array<string> = [];
  isLoading: boolean = false;


  constructor(
    private fb: FormBuilder,
    private config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private mobileEvent: MobileEventService,
    private elementRef: ElementRef,) {
    this.termsSub = this.config.terms.subscribe(res => {
      this.termsUrl = res;
    });
    this.form = this.fb.group({
      pan: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(16)], [CardNumberValidator]],
      firstName: [{ value: '', disabled: true }],
      lastName: [{ value: '', disabled: true }],
      email: [{ value: '', disabled: true }],
      termsAndConditions: [{ value: false, disabled: true }],
      privacy: [{ value: false, disabled: true }]
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('token')) {
      this.router.navigateByUrl('form')
    }

    this.availableFieldsSub = this.config.availableFields.subscribe(res => {
      if (!res) return;
      this.toogleFormFields(res);
    });

    this.requiredFieldsSub = this.config.requiredFields.subscribe(res => {
      if (!res) return;
      this.setValidatorsAndOptionalFields(res);
    });

    this.showConfirmationScreen = this.config.isConfirmationScreenVisible();
  }

  ngAfterViewInit() {
    this.setFocus('#cardNo', false);

    this.timeout = interval(100)
      .pipe(take(1))
      .subscribe(() => {
        this.setFocus('#cardNo');
      });
  }


  submit() {
    this.setFocus('#submit');

    this.isLoading = true;
    const body = Object.assign(this.form.value);
    this.setEnrollmentBody(body);
    body.pan = body.pan.toString();
    this.enroll.enroll(body).subscribe(
      res => {
        this.mobileEvent.emitEvent({ ...res.data, status: 'SUCCESS_ENROLLMENT' });
        if (!this.showConfirmationScreen) return;
        this.isLoading = false;
        this.router.navigate(['enroll/success']);
      },
      error => {
        this.mobileEvent.emitEvent({ ...error.error, status: 'ERROR_ENROLLMENT' });
        if (!this.showConfirmationScreen) return;
        this.isLoading = false;
        this.router.navigate(['enroll/error']);
      }
    );
  }

  setEnrollmentBody(body) {
    const pan = body.pan.toString().replace(' ', '');
    const partnerData = JSON.parse(sessionStorage.getItem('partnerData'));

    Object.assign(body, {
      lastFourDigits: pan.slice(-4),
      bin: pan.slice(0, 6),
      companyId: partnerData.companyId,
      customerId: partnerData.customerId,
      programId: partnerData.programId
    });
  }

  toogleFormFields(availableFields): void {
    for (let control in this.form.controls) {
      if (!availableFields[control]) {
        this.form.get(control).disable();
      } else {
        this.form.get(control).enable();
      }
    }
  }

  setValidatorsAndOptionalFields(requiredFields): void {
    const isFieldRequired = (field) => requiredFields[field];
    const isControlEnabled = (field) => this.form.controls[field].enabled;

    Object.keys(requiredFields)
      .forEach((field) => {
        if (isFieldRequired(field) === false && isControlEnabled(field)) {
          return this.optionalFields.push(field);
        }

        const fieldValidators: Record<string, ValidatorFn[]> = {
          'termsAndConditions': [
            Validators.required,
            Validators.requiredTrue
          ],
          'privacy': [
            Validators.required,
            Validators.requiredTrue
          ],
          defaultValidators: [Validators.required]
        };

        const validatorsForCurrentField = fieldValidators[field] || fieldValidators.defaultValidators;

        this.form.controls[field].setValidators(validatorsForCurrentField)
      })
  }

  get panControl() {
    return this.form?.get('pan');
  }

  onClear() {
    this.form.get('pan').setValue('');
  }

  private setFocus(selector: string, focus: boolean = true) {
    const elem: HTMLElement = this.elementRef.nativeElement.querySelector(selector);
    if (elem) {
      if (focus) {
        elem.focus();
      } else {
        elem.blur();
      }
    }
  }

  ngOnDestroy() {
    this.termsSub.unsubscribe();
    this.availableFieldsSub.unsubscribe();
    this.requiredFieldsSub.unsubscribe();
    this.timeout.unsubscribe();
  }
}
