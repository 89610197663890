import { Config } from '../dto/ui-config';


export class ConfigEntriesValidator {
    static check(cfg: Config): boolean {
        if (
            !cfg.primaryColor ||
            !cfg.secondaryColor ||
            !cfg.primaryTextColor ||
            !cfg.secondaryTextColor
        ) {
            return false;
        }
        return true;
    }
}