<div class="container-fluid">
  <div class="row">
    <div class="content col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">
      <p>{{ 'register.tittle' | translate }}</p>
      <div *ngIf="showAdditionalGraphics" class="img-wrapper"></div>
      <form [formGroup]="form">
        <div class="inputs-wrapper">
        <div class="col-12 form-label-group" *ngIf="form.get('pan').enabled">
          <span *ngIf="panControl.valid"
                class="check-icon"></span>
          <span *ngIf="panControl.invalid && panControl.value"
                class="clear-icon" (click)="onClear()"></span>
          <input
            #pan
            formControlName="pan"
            type="number"
            id="cardNo"
            class="number-dotted"
            data-maxlength="16"
            oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
            placeholder="{{ 'register.placeholders.card_number' | translate }}"
            inputmode="numeric"
            required>
          <label for="cardNo">{{ 'register.userData.card_number' | translate }}</label>
          <div class="validation-message" *ngIf="form.get('pan').hasError('validateCardNumber')">{{ 'register.invalid_card_no' | translate }}</div>
        </div>
        <div class="col-12 form-label-group" *ngIf="form.get('firstName').enabled">
          <input id="firstName" formControlName="firstName" type="text"
                 placeholder="{{ 'register.placeholders.first_name' | translate }}">
          <label for="firstName">{{ 'register.userData.first_name' | translate }}
            <span *ngIf="optionalFields.includes('firstName')" class="star">*</span>
          </label>
        </div>
        <div class="col-12 form-label-group" *ngIf="form.get('lastName').enabled">
          <input id="lastName" formControlName="lastName" type="text"
                 placeholder="{{ 'register.placeholders.last_name' | translate }}">
          <label for="lastName">{{ 'register.userData.last_name' | translate }}
            <span *ngIf="optionalFields.includes('lastName')" class="star">*</span>
          </label>
        </div>
        <div class="col-12 form-label-group" *ngIf="form.get('email').enabled">
          <input id="email" formControlName="email" type="text"
                 pattern="^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"
                 placeholder="{{ 'register.placeholders.email' | translate }}">
          <label for="email">{{ 'register.userData.email' | translate }}
            <span *ngIf="optionalFields.includes('email')" class="star">*</span>
          </label>
          <div class="validation-message"
               [hidden]="!form.get('email').hasError('pattern')">{{ 'register.invalid_email' | translate }}</div>
        </div>
        <div *ngIf="optionalFields.length" class="col-12 form-legend">
          <span class="star">*</span>{{ 'register.optional' | translate }}
        </div>
        <div class="col-12 terms" *ngIf="form.get('termsAndConditions').enabled">
          <div class="round">
            <input type="checkbox" id="termsAndConditions" formControlName="termsAndConditions"/>
            <label for="termsAndConditions"></label>
          </div>
          <a [href]="termsUrl" target="_blank">{{'register.accept_terms' | translate }}</a>
        </div>
        <div class="col-12 terms" *ngIf="form.get('privacy').enabled">
          <div class="round">
            <input type="checkbox" id="privacy" formControlName="privacy"/>
            <label for="privacy"></label>
          </div>
          <span>{{'register.accept_privacy' | translate }}</span>
        </div>
        </div>
        <div class="button-wrapper">
        <div class="powered-by">
          <div>powered by</div>
          <img src="assets/mcLogo.png">
        </div>
        <div class="col-12 submit-btn">
          <button id="submit" [disabled]="!form.valid" (click)="submit()">
                      <span *ngIf="!isLoading">
                        {{ 'register.confirm' | translate }}
                      </span>
            <div class="spinner" *ngIf="isLoading"></div>
          </button>
        </div>
        </div>
      </form>
    </div>
  </div>
</div>


