<div class="container-fluid" *ngIf="cardsList.length > 1">
    <div class="row">
        <div class="col-10 offset-1">
            <p>{{'cards_list.tittle' | translate }}</p>
        </div>
    </div>
    <div class="row card-list">
        <div class="col-7 offset-1">{{'cards_list.card_no' | translate }}</div>
        <div class="col-3" *ngIf="isEdit">{{ 'cards_list.edit' | translate }}</div>
        <div class="col-3" *ngIf="!isEdit">{{ 'cards_list.delete' | translate }}</div>
        <ng-container *ngFor="let card of cardsList">
            <div class="col-7 offset-1">{{card.firstFourDigits}} **** **** {{card.lastFourDigits}}</div>
            <div class="col-3">
                <span *ngIf="isEdit" class="material-icons" (click)="navigateToForm(card.cardId, card.lastFourDigits)">edit</span>
                <span *ngIf="!isEdit" class="material-icons" (click)="openModal(card)">delete</span>
            </div>
        </ng-container>
    </div>
  <app-modal></app-modal>
</div>
