import { WordArray, LibWordArray, DecryptedMessage } from 'crypto-js';
import * as cryptoJs from 'crypto-js';

export class AES {
  private aesKey: any;
  private truncatedAesKey: any;
  private CryptoJS = cryptoJs;

  constructor() {}

  public encrypt(value: string | LibWordArray): string {
    this.aesKey = this.CryptoJS.lib.WordArray.random(32);
    this.truncatedAesKey = this.CryptoJS.lib.WordArray.create(this.aesKey.words.slice(0, 4));
    const iv = this.CryptoJS.lib.WordArray.create(this.aesKey.words.slice(4, 8));
    const encrypted: any = this.CryptoJS.AES.encrypt(value, this.truncatedAesKey, {
      mode: this.CryptoJS.mode.CBC,
      iv: iv,
    });
    return encrypted.ciphertext.toString(this.CryptoJS.enc.Base64);
  }

  public getKey(): WordArray {
    return this.aesKey.toString(this.CryptoJS.enc.Hex);
  }
}
