import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration/registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessComponent } from './success/success.component';
import { ErrorComponent } from './error/error.component';
import { TranslateModule } from "@ngx-translate/core";
import { CardListComponent } from './card/list/card-list.component';
import { EditCardComponent } from './card/edit/edit-card.component';
import {ModalComponent} from "./modal/modal.component";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule
  ],
	declarations: [
    RegistrationComponent, 
    SuccessComponent, 
    ErrorComponent,
    CardListComponent,
    EditCardComponent,
    ModalComponent
  ],
})

export class EnrollmentModule { }
