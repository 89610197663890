import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {PartnerData} from "../../domain/instance-menagement/dto/partner-data";
import {EMPTY, Observable} from "rxjs";
import {catchError, concatMap, map, tap} from "rxjs/operators";
import {InstanceMenagement} from "../../domain/instance-menagement/instance-menagement";
import {InstanceApi} from "../../adapters/instance-api";
import {ActionCodes} from "../../domain/instance-menagement/dto/partner-data"
import { ConfigService } from '../services/config.service';

@Injectable()
export class PartnerDataResolver implements Resolve<PartnerData> {

  private instanceMgmt = new InstanceMenagement(new InstanceApi());

  constructor(
    private router: Router,
    private config: ConfigService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const tokenID: string = route.paramMap.get('token');

    return this.instanceMgmt.getPartnerData(tokenID)
      .pipe(
        catchError(() => {
          this.router.navigate(['partner-data/error']);
          return EMPTY;
        }),
        concatMap(partnerData => this.instanceMgmt.getConfigOfInstance(partnerData['companyId'], partnerData['language'] || 'pl').pipe(
          tap(instanceConfig => {
            this.config.applyConfig(instanceConfig);
            sessionStorage.setItem('partnerData', JSON.stringify(partnerData));
            switch (partnerData.actionCode) {
              case ActionCodes.N:
                this.router.navigate(['form']);
              break;
              case ActionCodes.R:
                this.router.navigate(['card-list/edit']);
              break;
              case ActionCodes.C:
                this.router.navigate(['card-list/delete']);
              break;
            }
            return EMPTY;
          }),
        ))
      );
  }
}
