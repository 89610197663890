import { ErrorHandler, Injectable } from '@angular/core';
import { SentryService } from './services/sentry.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private sentryService: SentryService) {}

  handleError(error: Error | string): void {
    this.sentryService.reportToSentry(error['originalError'] || error);
  }
}
