import {TranslateLoader} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {ConfigService} from "../shared/services/config.service";
import {InstanceMenagement} from "../domain/instance-menagement/instance-menagement";
import {InstanceApi} from "../adapters/instance-api";

export class TranslateApiLoader implements TranslateLoader {

  url: string = '';
  private instanceMenagement = new InstanceMenagement(new InstanceApi());

  constructor(private config: ConfigService) {
    this.url = config.translationUrl;
  }

  public getTranslation(): Observable<object> {
    return this.instanceMenagement.getTranslationFromUrl(this.url);
  }

}
