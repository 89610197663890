import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';

@Injectable()
export class SentryService {
  private sentry;

  constructor() {
    let dsn: string;
    if (environment.production) {
      dsn = 'https://d7bfab007eb749829540305cd1ca6dd0@sentry-kubernetes.upaid.pl/21';
    } else {
      dsn = 'https://9222cf38f84c468497d9106a93722bc0@sentryk8s.upaidtest.pl/28';
    }
    Sentry.init({
      dsn: dsn
    });
  }

  /**
   * Sends error to sentry or shows it in console
   * @param {Error} error Error object which needs to be reported to sentry
   */
  reportToSentry(error: Error): void {
    Sentry.captureException(error['originalException'] || error);
    console.error(error);
  }
}
