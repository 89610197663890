import NodeRSA from 'node-rsa';
import forge from 'node-forge';

export class RSA {
  public sign(data: any, cert: string): string {
    const certPem = forge.pki.certificateFromPem(cert);
    const publicKeyPem = forge.pki.publicKeyToPem(certPem.publicKey);
    let buffer = Buffer.from(data, 'hex');
    const key: NodeRSA = new NodeRSA(publicKeyPem, 'pkcs8-public-pem', { encryptionScheme: 'pkcs1' });
    const encryptedAesKey: string = key.encrypt(buffer, 'base64');
    return encryptedAesKey;
  }
}
