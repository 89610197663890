import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Config } from 'src/app/domain/instance-menagement/dto/ui-config';

@Injectable()

export class ConfigService {
  public terms = new BehaviorSubject('');
  public additionalStyles = new BehaviorSubject('');
  public availableFields = new BehaviorSubject('');
  public requiredFields = new BehaviorSubject('');
  private configLoaded: boolean = false;
  private currentInstanceConfig: Config;
  private appConfig: {[key: string]: any} = {
    key_combination: ['shift','f1'],
    times_to_repeat: 3
  };
  public translationUrl: string = '';

  constructor(@Inject(DOCUMENT) private document: HTMLDocument) {}

  applyConfig(config: Config): void {
    this.currentInstanceConfig = config;

    document.documentElement.style.setProperty('--primary_color', config.primaryColor);
    document.documentElement.style.setProperty('--secondary_color', config.secondaryColor);
    document.documentElement.style.setProperty('--primary_text_color', config.primaryTextColor);
    document.documentElement.style.setProperty('--secondary_text_color', config.secondaryTextColor);

    if (config.logotypeUrl)
      document.documentElement.style.setProperty('--logo_img', `url('${config.logotypeUrl}')`);

    config.backgroundFileUrl ?
      document.documentElement.style.setProperty('--background_img', `url('${config.backgroundFileUrl}')`)
    : document.documentElement.style.setProperty('--background_img', 'none');

    this.emitTerms(config.termsAndConditionsUrl);
    this.emitAdditionalStyles(config.additionalStyles);
    this.emitAvailableFields(config.availableFields);
    this.emitRequiredFields(config.requiredFields);
    this.translationUrl = config.translationUrl;
    this.configLoaded = true;
  }

  emitTerms(terms: string): void {
    this.terms.next(terms);
  }

  emitAdditionalStyles(styles: string): void {
    this.additionalStyles.next(styles)
  }

  emitAvailableFields(fields): void {
    this.availableFields.next(fields);
  }
  emitRequiredFields(fields): void {
    this.requiredFields.next(fields);
  }

  getAppConfig(): Observable<any> {
    return of(this.appConfig);
  }

  isConfirmationScreenVisible(): boolean {
    return this.currentInstanceConfig.showConfirmationScreen;
  }

  getInstancesList(): Observable<any> {
    return of([
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance1'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance2'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance3'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance4'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance5'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance6'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance7'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance8'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance9'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance10'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance10'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance10'},
      {id: '123e4567-e89b-12d3-a456-426614174000', name: 'Instance10'},
    ])
  }

  checkConfig(): boolean {
    return this.configLoaded;
  }
}

