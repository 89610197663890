<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 offset-md-6 success-info">
           <h1>{{ 'success.title' | translate }}</h1>
           <p>{{ 'success.' + action | translate }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="img-wrapper"></div>
        </div>
    </div>
</div>

