import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SentryService } from './shared/services/sentry.service';
import { ConfigService } from './shared/services/config.service';
import { RedirectService } from './shared/services/redirect.service';
import { GlobalErrorHandler } from './shared/globalErrorHandler';
import { HttpClientModule } from '@angular/common/http';
import { EnrollmentModule } from './enrollment/enrollment.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstanceConfigurationComponent } from './instance-configuration/instance-configuration.component';
import { ConfigurationGuard } from './shared/guards/configuration.guard';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateApiLoader } from "./translate/translate.loader";
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { ColorPickerModule } from 'ngx-color-picker';
import { InstanceSelectorComponent } from './instance-configuration/instance-selector/instance-selector.component'
import { PartnerDataResolver } from "./shared/resolvers/partner-data.resolver";
import { ModalService} from "./shared/services/modal.service";
import { PartnerDataGuard } from './shared/guards/partner-data.guard';
import { ConfigResolver } from './shared/resolvers/config.resolver';
import { LoaderComponent } from './shared/components/loader/loader.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (configService: ConfigService) => new TranslateApiLoader(configService),
        deps: [ConfigService]
      },
      defaultLanguage: 'en'
    }),
    EnrollmentModule,
    ColorPickerModule,
    NgxSmartModalModule.forRoot()
  ],
  declarations: [
    AppComponent,
    InstanceConfigurationComponent,
    InstanceSelectorComponent,
    LoaderComponent
  ],
  providers: [
    SentryService,
    ConfigService,
    ConfigurationGuard,
    PartnerDataGuard,
    RedirectService,
    ModalService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    PartnerDataResolver,
    ConfigResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
