import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { EnrollmentApi } from 'src/app/adapters/enrollment-api';
import { Enroll } from 'src/app/domain/enrollment/enroll';
import { KeyWrapper } from 'src/app/domain/utils/crypto/KeyWrapper';
import { CardNumberValidator } from 'src/app/shared/validators/card-number.validator';
import {Subscription} from "rxjs";
import {ReplacementData} from "../../../domain/enrollment/dto/replacement-data";
import { LoaderService } from '../../../shared/services/loader.service';
import { ConfigService } from '../../../shared/services/config.service';
import { MobileEventService } from '../../../shared/services/mobile-event.service';

@Component({
  selector: 'app-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})
export class EditCardComponent implements OnInit {
  public form: FormGroup;

  public enroll = new Enroll(new EnrollmentApi(), new KeyWrapper());
  private routerSub: Subscription;
  private showConfirmationScreen: boolean = false;
  isLoading: boolean = false;
  last4digits: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private config: ConfigService,
    private mobileEvent: MobileEventService,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      token: [''],
      cardId: [''],
      pan: ['', [Validators.required, Validators.maxLength(16)], [CardNumberValidator]],
      encryptedPan: [''],
      bin: [''],
      lastFourDigits: ['']
    });


    this.route.params.subscribe(params => {
      this.last4digits = params.last4digits;
      this.form.get('cardId').patchValue(params.cardId);
    });

    let token = JSON.parse(sessionStorage.getItem('partnerData'))['authToken'];
    this.form.get('token').patchValue(token);

    this.showConfirmationScreen = this.config.isConfirmationScreenVisible();
  }

  public edit(): void {
    const elem: HTMLElement = this.elementRef.nativeElement.querySelector('#submit');
    if (elem) {
      elem.focus();
    }

    this.isLoading = true;
    this.extractBinAndLastForuDigits(this.form.get('pan').value.toString());
    const body: ReplacementData = this.form.value;
    body.pan = body.pan.toString();
    this.enroll.replace(body).subscribe(res => {
      this.mobileEvent.emitEvent({...res.data, status: 'SUCCESS_REPLACEMENT'});
      if(!this.showConfirmationScreen) return;
        this.isLoading = false;
        this.router.navigateByUrl('edit-card/success');
    },
    error => {
      this.mobileEvent.emitEvent({...error.error, status: 'ERROR_REPLACEMENT'});
      if(!this.showConfirmationScreen) return;
      this.isLoading = false;
      this.router.navigateByUrl('edit-card/error');
    })
  }

  get panControl() {
    return this.form?.get('pan');
  }

  onClear(): void {
    this.form.get('pan').setValue('');
  }

  private extractBinAndLastForuDigits(pan: string): void {
    this.form.get('bin').patchValue(pan.slice(0, 6));
    this.form.get('lastFourDigits').patchValue(pan.slice(-4));
  }

  public navigateToCardsList(): void {
    this.router.navigate(['/card-list/edit']);
  }
}
