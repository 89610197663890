<div class="container-fluid">
    <div class="instance-selector-trigger" (click)="getInstancesList()">Select instance</div>
    <div class="row">
        <div class="col-4 editor-wrapper">
            <div class="instance-selector-wrapper" [ngClass]="{'show': showSelector}">
                <app-instance-selector (instanceOutput)="instanceSelected($event)"></app-instance-selector>
            </div>
            <form [formGroup]="form">
                <div class="col-12">
                    <label class="name-label" for="name">Name</label>
                    <input class="name-input" type="text" formControlName="name" id="name">
                </div>
                <div class="col-12">
                    <label for="primaryColor">Primary color</label>
                    <input
                        class="color-input"
                        formControlName="primaryColor"
                        [colorPicker]="form.get('primaryColor').value"
                        (colorPickerChange)="form.get('primaryColor').setValue($event)"
                        type="text" 
                        id="primaryColor" 
                        [cpPosition]="'bottom'"
                    >
                    <span class="selected-color-circle" [style.background]="form.get('primaryColor').value"></span>
                </div>
                <div class="col-12">
                    <label for="secondaryColor">Secondary color</label>
                    <input
                        class="color-input"
                        formControlName="secondaryColor"
                        [colorPicker]="form.get('secondaryColor').value"
                        (colorPickerChange)="form.get('secondaryColor').setValue($event)"
                        type="text" 
                        id="secondaryColor" 
                        [cpPosition]="'bottom'"
                    >
                    <span class="selected-color-circle" [style.background]="form.get('secondaryColor').value"></span>
                </div>
                <div class="col-12">
                    <label for="primaryTextColor">Primary text color</label>
                    <input
                        class="color-input"
                        formControlName="primaryTextColor"
                        [colorPicker]="form.get('primaryTextColor').value"
                        (colorPickerChange)="form.get('primaryTextColor').setValue($event)"
                        type="text" 
                        id="primaryTextColor" 
                        [cpPosition]="'bottom'"
                    >
                    <span class="selected-color-circle" [style.background]="form.get('primaryTextColor').value"></span>
                </div>
                <div class="col-12">
                    <label for="secondaryTextColor">Secondary text color</label>
                    <input
                        class="color-input"
                        formControlName="secondaryTextColor"
                        [colorPicker]="form.get('secondaryTextColor').value"
                        (colorPickerChange)="form.get('secondaryTextColor').setValue($event)"
                        type="text" 
                        id="secondaryTextColor" 
                        [cpPosition]="'bottom'"
                    >
                    <span class="selected-color-circle" [style.background]="form.get('secondaryTextColor').value"></span>
                </div>
                <div class="col-12">
                    <label for="secondaryTextColor">Logo</label>
                </div>
                <div class="col-12">
                    <input type="file" id="logoUpload" (change)="logoChange(logoInput)" #logoInput class="custom-file-input"/>
                    <label for="logoUpload" class="custom-file-upload">
                        Choose file...
                    </label>
                    <input type="text" class="file-name" [value]="logoImageName" readonly>
                </div>
                <div class="col-12">
                    <label for="secondaryTextColor">Background image</label>
                </div>
                <div class="col-12">
                    <input type="file" id="backgroundUpload" (change)="backgroundChange(backgroundInput)" #backgroundInput class="custom-file-input"/>
                    <label for="backgroundUpload" class="custom-file-upload">
                        Choose file...
                    </label>
                    <input type="text" class="file-name" [value]="backgroundImageName" readonly>
                </div>
                <div class="col-12">
                    <label>Fields</label>
                </div>
                <div class="col-12" formGroupName="availableFields">
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="pan" formControlName="pan"/>
                            <label for="pan"></label>
                        </div>
                        <label>Card Number</label>
                    </div>
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="firstName" formControlName="firstName"/>
                            <label for="firstName"></label>
                        </div>
                        <label>First Name</label>
                    </div>
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="lastName" formControlName="lastName"/>
                            <label for="lastName"></label>
                        </div>
                        <label>Last Name</label>
                    </div>
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="email" formControlName="email"/>
                            <label for="email"></label>
                        </div>
                        <label>E-mail</label>
                    </div>
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="cfgTermsAndConditions" formControlName="termsAndConditions"/>
                            <label for="cfgTermsAndConditions"></label>
                        </div>
                        <label>Terms & Conditions</label>
                    </div>
                    <div class="fields-wrapper">
                        <div class="round">
                            <input type="checkbox" id="cfgPrivacy" formControlName="privacy"/>
                            <label for="cfgPrivacy"></label>
                        </div>
                        <label>Privacy Policy</label>
                    </div>
                </div>
                <div class="col-12">
                    <label>Terms</label>
                </div>
                <!-- <div class="col-12" formArrayName="terms">
                    <ng-container *ngFor="let item of this.form.get('terms')['controls']; let i = index;">
                        <div class="terms-wrapper" [formGroupName]="i">
                            <div class="wrap-checkbox">
                                <label>Required</label>
                                <div class="round">
                                    <input type="checkbox" [attr.id]="'terms' + i"  formControlName="required"/>
                                    <label [attr.for]="'terms' + i"></label>
                                </div>
                            </div>
                            <div class="wrap-input">
                                <label>Title</label>
                                <input type="text" formControlName="title">
                            </div>
                            <div class="wrap-remove">
                                <span class="remove-icon" (click)="removeTerms(i)">+</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="formArray.length < 5" class="col-12">
                    <span class="add-icon" (click)="addTerms()">+</span>
                </div> -->
                <div class="col-12 textarea-wrapper">
                    <label for="additionalStyles">Additional styles</label>
                    <textarea formControlName="additionalStyles" id="additionalStyles"></textarea>
                </div>
                <div class="col-12">
                    <label for="transalationFile">Translation file</label>
                </div>
                <div class="col-12">
                    <input type="file" id="transalationUpload" (change)="tranlationChange(translateInput)" #translateInput class="custom-file-input"/>
                    <label for="transalationUpload" class="custom-file-upload">
                        Choose file...
                    </label>
                    <input type="text" class="file-name" [value]="translationFileName" readonly>
                </div>
                <div class="col-12 button-wrapper">
                    <button (click)="saveConfiguration()">Save</button>
                    <button (click)="cancel()">Cancel</button>
                </div>
            </form>
        </div>
        <div class="col-8 preview-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<ngx-smart-modal #myModal identifier="myModal" [closable]="false" [escapable]="false" [dismissable]="false">
    <p>Enter the password</p>
    <input type="password" #passInput (input)="myModal.setData(passInput.value, true)">
    <button (click)="myModal.close(); passInput.value = ''">Authorize</button>
    <button (click)=" myModal.setData(null, true); passInput.value = ''; myModal.close()">Cancel</button>
</ngx-smart-modal>