import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ConfigService } from './shared/services/config.service';
import { RedirectService } from './shared/services/redirect.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  public title = 'mrs-enrollment';
  public customStyles: SafeHtml;
  private styleSub: Subscription;

  constructor(
    private config: ConfigService,
    private redirect: RedirectService,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) public document: HTMLDocument
  ) {
    this.styleSub = this.config.additionalStyles.subscribe(res => this.applyStyles(res));
  }

  applyStyles(styles: string): void {
    this.customStyles = this.sanitizer.bypassSecurityTrustHtml(`<style>${styles}</style>`);
  }

  ngOnDestroy(): void {
    this.styleSub.unsubscribe();
  }
}
