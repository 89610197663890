import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EnrollResponseData, MobileEventData } from '../../domain/enrollment/dto/mobile-event-data';

@Injectable({
  providedIn: 'root'
})
export class MobileEventService {
  private os: string;
  constructor(private deviceService: DeviceDetectorService) {
    this.os = this.deviceService.getDeviceInfo().os.toLowerCase();
  }

  public emitEvent(data): void {
    const mappedData = new MobileEventData(data);
    console.log(mappedData);

    if ((window as any).flutter_inappwebview) {
      (window as any).flutter_inappwebview.callHandler('enrollmentEnded', mappedData);
    } else if (this.os === 'android') {
      (window as any).Android.enrollmentEnded(mappedData);
    } else if (this.os === 'ios') {
      (window as any).webkit.messageHandlers.enrollmentEnded.postMessage(mappedData);
    }
  }
}
