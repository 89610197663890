import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

/**
 * Checks if the card number is correct and removes spaces
 * @param {AbstractControl} control This is the base class for FormControl which tracks the value and validation status of an individual form control
 * @returns {validateCardNumber: boolean} In case the card number is incorrect, we get an error (validateCardNumber: true)
 */
export function luhnCheck(cardNumber: string) {
  const numbersArray: Array<number> = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
    testNumber: string = cardNumber.replace(/[ -]/g, '');
  let len: number = testNumber.length,
    bit: number = 1,
    sum: number = 0,
    value: number;

  while (len) {
    value = parseInt(testNumber.charAt(--len), 10);
    sum += (bit ^= 1) ? numbersArray[value] : value;
  }

  return sum && sum % 10 === 0;
}

export const CardNumberValidator: ValidatorFn = (control: AbstractControl): Observable<any> => {
  const delay = timer(1000);
  const cardNumber: string = (control.value ?? '').toString().replace(/[ -]/g, '');
  if(cardNumber.length === 16 && !luhnCheck(cardNumber)) {
    return of({
      validateCardNumber: true
    });
  } else if(cardNumber.length === 16) {
    return of(null);
  }
  return delay.pipe(switchMap(() => {
    return of({validateCardNumber: true})
  }))
};
