export enum ActionCodes {
  N = 'N',
  R = 'R',
  C = 'C'
}

export interface PartnerData {
  companyId: string,
  customerId?: string,
  actionCode: ActionCodes,
  programId: string,
  authToken: string
}
