import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrationComponent } from './enrollment/registration/registration.component';
import { SuccessComponent } from './enrollment/success/success.component';
import { ErrorComponent } from './enrollment/error/error.component';
import { InstanceConfigurationComponent } from './instance-configuration/instance-configuration.component';
import { ConfigurationGuard } from './shared/guards/configuration.guard'
import { PartnerDataResolver } from "./shared/resolvers/partner-data.resolver";
import { CardListComponent } from './enrollment/card/list/card-list.component';
import { EditCardComponent } from './enrollment/card/edit/edit-card.component';
import { PartnerDataGuard } from './shared/guards/partner-data.guard';
import { ConfigResolver } from './shared/resolvers/config.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'form', pathMatch: 'full'},
  { path: '', resolve: {config: ConfigResolver}, children: [
    { path: 'form', component: RegistrationComponent, canActivate: [PartnerDataGuard] },
    { path: 'form/:token', component: RegistrationComponent, resolve: { partnerData: PartnerDataResolver }},
    { path: ':action/success', component: SuccessComponent },
    { path: ':action/error', component: ErrorComponent },
    { path: 'instance-configuration', component: InstanceConfigurationComponent,  canActivate: [ConfigurationGuard], children: [
      { path: '', redirectTo: 'preview', pathMatch: 'prefix' },
      { path: 'preview', component: RegistrationComponent}
    ] },
    { path: 'card-list/:action', component: CardListComponent, canActivate: [PartnerDataGuard] },
    { path: 'edit-card/:cardId/:last4digits', component: EditCardComponent, canActivate: [PartnerDataGuard] },
  ] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

