import { EnrollmentData } from './dto/enrollment-data'
import { EncodedEnrollmentData } from './dto/encoded-enrollment-data'
import { LuhnValidator } from './validators/luhn-validator'
import { Error, ErrorType } from '../utils/error'
import { throwError, Observable, of } from 'rxjs';
import { EnrollmentPort } from './ports/enrollment'
import { mergeMap } from 'rxjs/operators';
import { KeyWrapper } from "../utils/crypto/KeyWrapper";
import { ReplacementData } from './dto/replacement-data';
import { CardListItem } from './dto/card-list-item';
import {DeleteCardData} from "./dto/delete-card-data";
import {EncodedReplacementData} from "./dto/encoded-replacement-data";


export class Enroll {

constructor(
  private port: EnrollmentPort,
  private keyWrapperSvc: KeyWrapper
) {}

  public enroll(enrollmentData: EnrollmentData): Observable<any> {
    if(!LuhnValidator.check(enrollmentData.pan)) {
      return throwError(new Error(ErrorType.INVALID_CARD_NO))
    }
    return this.port.getPublicKey().pipe(
      mergeMap(res => {
        const encodedEnrollmentData = this.encodeData<EnrollmentData>(enrollmentData, res['publicKey']);
        delete encodedEnrollmentData['pan'];
        return this.port.sendEnrollRequest(encodedEnrollmentData).pipe(
          mergeMap(response =>  of({data: response}))
        );
      })
    )
  }

  public replace(replacementData: ReplacementData) {
    if(!LuhnValidator.check(replacementData.pan)) {
      return throwError(new Error(ErrorType.INVALID_CARD_NO))
    }
    return this.port.getPublicKey().pipe(
      mergeMap(res => {
        const encodedReplacementData = this.encodeData<ReplacementData>(replacementData, res['publicKey']);
        delete encodedReplacementData['pan'];
        return this.port.sendReplacementRequest(encodedReplacementData).pipe(
          mergeMap(response =>  of({data: response}))
        );
      })
    )
  }

  public getCardsList(token: string): Observable<CardListItem[]> {
    return this.port.getCardsList(token).pipe(
      mergeMap(response =>  of(response))
    );
  }

  public deleteCard(body: DeleteCardData): Observable<any> {
    return this.port.deleteCard(body).pipe(
      mergeMap(response =>  of(response))
    );
  }

  private encodeData<T>(data: T, key: string): EncodedEnrollmentData extends T ? EncodedEnrollmentData : EncodedReplacementData {
    let encryptedData = this.keyWrapperSvc.wrap(data['pan'], key);

    return {...data, ...encryptedData};
  }

}
