import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ConfigService } from '../shared/services/config.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { InstanceMenagement } from '../domain/instance-menagement/instance-menagement';
import { InstanceApi } from '../adapters/instance-api';

@Component({
  selector: 'app-instance-configuration',
  templateUrl: './instance-configuration.component.html',
  styleUrls: ['./instance-configuration.component.scss']
})
export class InstanceConfigurationComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public color: any;
  public backgroundImageName: string = '';
  public logoImageName: string = '';
  public translationFileName: string = '';
  public formArray: FormArray;
  public showSelector: boolean = false;
  private stylesSub: Subscription;
  private termsSub: Subscription;
  private additionalStylesSub: Subscription;
  private instanceMgmt = new InstanceMenagement(new InstanceApi());

  constructor(private fb: FormBuilder, private configService: ConfigService, private router: Router, public modalService: NgxSmartModalService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [''],
      primaryColor: [''],
      secondaryColor: [''],
      primaryTextColor: [''],
      secondaryTextColor: [''],
      backgroundFileUrl: [''],
      logotypeUrl: [''],
      availableFields: this.fb.group({
        pan: [true],
        firstName: [true],
        lastName: [true],
        email: [true],
        termsAndConditions: [true],
        privacy: [true],
      }),
      terms: this.fb.array([]),
      additionalStyles: [''],
      translation_file: ['']
    });
    this.formArray = this.form.get('terms') as FormArray;
    this.stylesSub = this.form.valueChanges.subscribe(() => this.configService.applyConfig(this.form.value));
    this.additionalStylesSub = this.form.get('additionalStyles').valueChanges.subscribe(change => this.configService.emitAdditionalStyles(change));
    this.termsSub = this.form.get('terms').valueChanges.subscribe(() => this.configService.emitTerms(this.form.get('terms').value));
  }

  updateStyles() {
    this.configService.applyConfig(this.form.value);
  }

  logoChange(input: HTMLInputElement): void {
    this.logoImageName = input.files[0].name;
    this.readFile(input.files[0], (result) => {
      const img = new Image();
      img.src = result;
      this.form.patchValue({
        logotypeUrl: img.src.replace(/(\r\n|\n|\r)/gm, "")
      })
    });
  }

  backgroundChange(input: HTMLInputElement): void {
    this.backgroundImageName = input.files[0].name;
    this.readFile(input.files[0], (result) => {
      const img = new Image();
      img.src = result;
      this.form.patchValue({
        backgroundFileUrl: img.src.replace(/(\r\n|\n|\r)/gm, "")
      })
    });
  }

  tranlationChange(input: HTMLInputElement): void {
    this.translationFileName = input.files[0].name;
    this.readFile(input.files[0], (result) => {
      this.form.patchValue({
        translation_file: result
      })
    });
  }

  readFile(file: File, callback: any): void {
    let reader = new FileReader();
    reader.onload = () => {
      callback(reader.result);
    }
    reader.readAsDataURL(file);
  }

  addTerms(): void {
    this.formArray.push(
      this.fb.group({
        title: [''],
        required: ['']
      })
    )
  }

  removeTerms(index: number): void {
    this.formArray.removeAt(index);
  }

  saveConfiguration(): void {
    const authorizationModal = this.modalService.get('myModal');
    authorizationModal.open();
    const modalSub: Subscription = authorizationModal.onClose.subscribe((modal: NgxSmartModalComponent) => {
      if(modal.getData()) {
        console.log(this.form.value);
      }
      authorizationModal.setData(null, true);
      modalSub.unsubscribe();
    });
  }

  cancel(): void {
    const partnerData = JSON.parse(sessionStorage.getItem('partnerData'));
    this.instanceMgmt.getConfigOfInstance(partnerData['companyId'], partnerData['language'] || 'pl').subscribe(res => {
      this.configService.applyConfig(res);
      sessionStorage.removeItem('allow-configuration')
      this.router.navigate(['/form']);
    })
  }

  toogleInstanceSelector(visible: boolean): void {
    this.showSelector = visible;
  }

  instanceSelected(instance: {id: string, name: string}): void {
    if(!instance) {
      this.toogleInstanceSelector(false);
      return;
    }
    const authorizationModal = this.modalService.get('myModal');
    authorizationModal.open();
    const modalSub: Subscription = authorizationModal.onClose.subscribe((modal: NgxSmartModalComponent)  => {
      if(modal.getData()) {
        this.toogleInstanceSelector(false);
      }
      authorizationModal.setData(null, true);
      modalSub.unsubscribe();
    });
  }

  getInstancesList() {
    const authorizationModal = this.modalService.get('myModal');
    authorizationModal.open();
    const modalSub: Subscription = authorizationModal.onClose.subscribe((modal: NgxSmartModalComponent)  => {
      if(modal.getData()) {
        this.toogleInstanceSelector(true);
      }
      authorizationModal.setData(null, true);
      modalSub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.stylesSub.unsubscribe();
    this.termsSub.unsubscribe();
    this.additionalStylesSub.unsubscribe();
  }
}
