import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable()

export class ModalService {
  modal: Object = {
    description: '',
    question: '',
    item: '',
    cancel: '',
    success: '',
    opened: false,
  };

  status: string = '';
  private modalOpen: BehaviorSubject<Object> = new BehaviorSubject<Object>(this.modal['opened']);
  private modalClose: Subject<string> = new Subject<string>();
  modalOpenEvent$: Observable<Object> = this.modalOpen.asObservable();
  modalCloseEvent$: Observable<string> = this.modalClose.asObservable();

  /**
   * Opens(shows) modal
   * @param { string } desc Modal description
   * @param { string } question Modal question
   * @param { string } item Modal additional data
   * @param { string } cancel Modal cancel button text
   * @param { string } success Modal success button text
   */
  open(desc?: string, question?: string, item?: string | object, cancel?: string, success?: string): void {
    this.modal['description'] = desc;
    this.modal['question'] = question;
    this.modal['item'] = item;
    this.modal['cancel'] = cancel;
    this.modal['success'] = success;
    this.modal['opened'] = true;
    this.modalOpen.next(this.modal);
  }

  /**
   * Closes modal
   */
  close(modalResponse: string): void {
    this.modal['opened'] = false;
    this.status = modalResponse;
    this.modalClose.next(this.status);
  }

}
