import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public loaderVisible: boolean = false;
  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.loaderVisible.subscribe(visible => {
      this.loaderVisible = visible;
    })
  }

}
