import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import { EnrollmentApi } from 'src/app/adapters/enrollment-api';
import { CardListItem } from 'src/app/domain/enrollment/dto/card-list-item';
import { Enroll } from 'src/app/domain/enrollment/enroll';
import { KeyWrapper } from 'src/app/domain/utils/crypto/KeyWrapper';
import {take, takeUntil} from "rxjs/operators";
import {ModalService} from "../../../shared/services/modal.service";
import { LoaderService } from '../../../shared/services/loader.service';
import { ConfigService } from '../../../shared/services/config.service';
import { MobileEventService } from '../../../shared/services/mobile-event.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit, OnDestroy {

  modalSubscription: Subscription;
  public isEdit: boolean = false;
  public cardsList: CardListItem[] = [];
  private routeSub: Subscription;
  private actionType: string = '';
  private showConfirmationScreen: boolean = false;
  public enroll = new Enroll(new EnrollmentApi(), new KeyWrapper());

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public modalService: ModalService,
    private loaderService: LoaderService,
    private config: ConfigService,
    private mobileEvent: MobileEventService) {
    this.routeSub = this.route.params.subscribe(res => {
      this.actionType = res.action;
      this.isEdit = this.actionType === 'edit';
    })
  }

  ngOnInit(): void {
    this.showConfirmationScreen = this.config.isConfirmationScreenVisible();

    this.enroll.getCardsList(this.getToken())
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
      this.cardsList = res;
      if (this.cardsList.length === 1) {
        let cardId = this.cardsList[0].cardId;
        this.isEdit ? this.navigateToForm(cardId, this.cardsList[0].lastFourDigits) : this.deleteCard(this.getToken(), cardId);
      }
    });
  }

  navigateToForm(id: string, last4digits: string) {
    this.router.navigate([`/edit-card/${id}/${last4digits}`]);
  }

  getToken(): string {
    let partnerData = JSON.parse(sessionStorage.getItem('partnerData'));
    return partnerData['authToken'];
  }

  openModal(card: CardListItem) {
    this.modalService.open(
      'delete_card.modal.tittle',
      'delete_card.modal.question',
      `${card.firstFourDigits} **** **** ${card.lastFourDigits}?`,
      'delete_card.modal.cancel',
      'delete_card.modal.confirm'
    );
    this.modalSubscription = this.modalService.modalCloseEvent$
      .pipe(take(1))
      .subscribe(response => {
        if (response === 'confirm') {
          this.deleteCard(this.getToken(), card.cardId);
        }
      });
  }

  deleteCard(token: string, id: string): void {
    this.loaderService.showLoader();
    const body: { token: string, cardId: string } = { token: token, cardId: id };
    this.enroll.deleteCard(body).subscribe(
      res => {
        this.mobileEvent.emitEvent({...res, status: 'SUCCESS_REMOVAL'});
        if(!this.showConfirmationScreen) return;
        this.loaderService.hideLoader();
        this.router.navigateByUrl('delete-card/success');
        },
      error => {
        this.mobileEvent.emitEvent({...error.error, status: 'ERROR_REMOVAL'});
        if(!this.showConfirmationScreen) return;
        this.loaderService.hideLoader();
        this.router.navigateByUrl('delete-card/error');
      }
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
