import { EnrollmentData } from "../domain/enrollment/dto/enrollment-data";
import { Observable, of } from "rxjs";
import {HttpClient, HttpHeaders, HttpParams, HttpXhrBackend} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import { ReplacementData } from '../domain/enrollment/dto/replacement-data';
import { CardListItem } from '../domain/enrollment/dto/card-list-item';
import {DeleteCardData} from "../domain/enrollment/dto/delete-card-data";

export class EnrollmentApi {
  private httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

  constructor() { };
  
  sendEnrollRequest(enrollmentData: EnrollmentData): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    return this.httpClient.post (`${environment.api_host}enrollment_widget/enroll`, {
      ...enrollmentData },{
      headers: headers
    }).pipe(
      map(res => res)
    );
  }

  sendReplacementRequest(replacementData: ReplacementData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    return this.httpClient.post(`${environment.api_host}enrollment_widget/card_replace`,replacementData, {headers: headers}).pipe(
      map(res => res)
    )
  }


  getCardsList(token: string): Observable<CardListItem[]> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json");
    let params = new HttpParams().set('token', token);
    return this.httpClient.get<CardListItem[]>(`${environment.api_host}enrollment_widget/cards_list`, {params: params, headers: headers}).pipe(
      map(res => res)
    );
  }

  deleteCard(body: DeleteCardData): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");

    return this.httpClient.post(`${environment.api_host}enrollment_widget/un_enroll`, {...body}, {
      headers: headers
    }).pipe(
      map(res => res)
    );
  }

  getPublicKey(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json");
    return this.httpClient.get(`${environment.api_host}enrollment_widget/enrollment/public_key`, {headers: headers}).pipe(
      map(res => res)
    );
  }
}
