import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  public action: string = 'enroll';

  constructor(private router: Router, private route: ActivatedRoute) {
    const paramMap = this.route.snapshot.paramMap;

    if(paramMap.get('action')) {
      this.action = paramMap.get('action');
    }

  }

  ngOnInit(): void {}

}
