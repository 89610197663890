<div class="custom-modal-background" [class.fade-in]="modalData['opened']" (click)="closeModal('cancel')"></div>
<div class="custom-modal-container" [class.fade-in]="modalData['opened']">
  <div class="custom-modal-body">
    <h2 class="modal-tittle">{{ modalData?.description | translate }} </h2>
    <p class="modal-question">{{ modalData?.question | translate }} {{ modalData?.item}}</p>
  </div>
  <div class="custom-modal-footer">
    <button class="confirm-button" (click)="closeModal('confirm')">{{ modalData?.success | translate}}</button>
    <span class="cancel-button btn-primary" (click)="closeModal('cancel')">{{ modalData?.cancel | translate}}</span>
  </div>
</div>
