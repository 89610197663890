export interface EnrollResponseData {
    status: string;
    statusCode?: string;
    parentId?: string;
    ranac?: string;
    designCode?: string;
    bin?: string;
    last4?: string;
}

export class MobileEventData {
    private status: string;
    private statusCode?: string;
    private parentId?: string;
    private ranac?: string;
    private designCode?: string;
    private bin?: string;
    private last4?: string;

    constructor(data: EnrollResponseData) {
        this.status = data.status;
        if(data.statusCode) {
            this.statusCode = data.statusCode;
        }
        if(data.parentId) {
            this.parentId = data.parentId;
        }
        if(data.ranac) {
            this.ranac = data.ranac;
        }
        if(data.designCode) {
            this.designCode = data.designCode;
        }
        if(data.bin) {
            this.bin = data.bin;
        }
        if(data.last4) {
            this.last4 = data.last4;
        }
    }
}
