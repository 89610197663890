import { Error, ErrorType } from '../utils/error'
import { throwError, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Config } from './dto/ui-config';
import { InstancePort } from './ports/instance';
import { ConfigEntriesValidator } from './validators/config-entries.validator';
import {PartnerData} from "./dto/partner-data";

export class InstanceMenagement {

    constructor(private port: InstancePort) {}

    public getConfigOfInstance(instanceName: string, language: string): Observable<Config> {
        return this.port.getConfig(instanceName, language).pipe(
            mergeMap(response =>  of(response))
        );
    }

    public createNewInstance(cfg: Config): Observable<any> {
        if(!ConfigEntriesValidator.check(cfg)) {
            return throwError(new Error(ErrorType.INVALID_CFG_OBJECT))
        }

        return this.port.create(cfg).pipe(
            mergeMap(response =>  of(response))
        );
    }

    public updateInstance(cfg: Config, instanceId: string): Observable<any> {
        if(!ConfigEntriesValidator.check(cfg)) {
            return throwError(new Error(ErrorType.INVALID_CFG_OBJECT))
        }

        return this.port.update(instanceId, cfg).pipe(
            mergeMap(response =>  of(response))
        );
    }

    public deleteInstance(instanceId: string): Observable<any> {
        return this.port.delete(instanceId).pipe(
            mergeMap(response =>  of(response))
        );
    }

    public getPartnerData(token: string): Observable<PartnerData> {
      return this.port.getPartner(token);
  }

  public getTranslationFromUrl(url: string): Observable<object> {
      return this.port.getTranslationFromUrl(url);
  }
  }
