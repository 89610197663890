<div class="container-fluid">
    <div class="row card-edit-form">
        <div class="content col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7">
            <form [formGroup]="form">
              <div class="form-label-group">
                <div class="current-card">
                  <p class="card-tittle">{{ 'edit_card.current_card' | translate }}</p>
                  <div>
                    <img src="assets/creditCard.png">
                    <div class="card-number">**** **** **** {{ last4digits }}</div>
                  </div>
                </div>
                <div class="col-12 form-label-group new-card">
                  <p class="card-tittle">{{ 'edit_card.new_card' | translate }}</p>
                  <p class="description">{{ 'edit_card.new_card_desc' | translate }}</p>
                  <span *ngIf="panControl.valid"
                        class="check-icon"></span>
                  <span *ngIf="panControl.invalid && panControl.value"
                        class="clear-icon" (click)="onClear()"></span>
                  <input
                    #pan
                    formControlName="pan"
                    id="cardNo"
                    class="number-dotted"
                    type="number"
                    placeholder="{{ 'edit_card.placeholders.card_number' | translate }}"
                    data-maxlength="16"
                    oninput="this.value=this.value.slice(0,this.dataset.maxlength)"
                    inputmode="numeric"
                    required>
                  <label for="cardNo">{{ 'edit_card.card_data.card_number' | translate }}</label>
                  <div class="validation-message" *ngIf="form.get('pan').hasError('validateCardNumber')">
                    {{ 'edit_card.invalid_card_no' | translate }}
                  </div>
                </div>
              </div>

              <div class="button-wrapper">
                <div class="powered-by">
                  <div>powered by</div>
                  <img src="assets/mcLogo.png">
                </div>
                <button id="submit" class="col-12 submit-btn" [disabled]="!form.valid" (click)="edit()">
                  <span *ngIf="!isLoading">
                  {{ 'edit_card.confirm' | translate }}
                  </span>
                  <div class="spinner" *ngIf="isLoading"></div>
                </button>
              </div>
            </form>
        </div>
    </div>
</div>
