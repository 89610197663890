<div class="instances-list">
    <div class="instance-filter">
        <label class="name-label" for="filter">Filter</label>
        <input class="name-input" type="text"  id="filter" #filter (input)="filterInstances(filter.value)">
        <span class="close-selector" (click)="emitInstance(null)">+</span>
    </div>
    <div class="list-item" *ngFor="let instance of filteredInstances" (click)="emitInstance(instance)">
        <p>{{instance.name}}</p>
        <p>{{instance.id}}</p>
    </div>
</div>
