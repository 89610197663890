import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  public incidenIdentfire: string = '64fdf345dss47';
  public action: string = 'no-partner-data';

  constructor(private router: Router, private route: ActivatedRoute) {
    const paramMap = this.route.snapshot.paramMap;
    if(paramMap.get('action')) {
      this.action = paramMap.get('action');
    }
  }

  ngOnInit(): void {
  }

}
